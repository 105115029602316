<template>
  <div>
    <!-- 左侧菜单栏-->
    <a-col :span="4">
      <a-menu
        style="min-height: 300px"
        mode="vertical"
        :default-selected-keys="activeMenuTab"
        v-model="activeMenuTab"
        v-if="isShowMenu('agents/finance')"
      >
        <a-menu-item
          v-for="item in showHasPermissionMenus"
          :key="item.routeName"
        >
          <router-link :to="{ name: item.routeName, params: { agentId: agentId }}">
            <a-icon :type="item.iconType" />
            {{ item.menuName }}
          </router-link>
        </a-menu-item>
      </a-menu>
    </a-col>

    <!--  内容-->
    <a-col :span="20">
      <div class="simiot-vertical-tab-content">
        <router-view />
      </div>
    </a-col>
  </div>
</template>

<script>
import { hasPermission } from '@/utils/permission'

export default {
  name: 'AgentCommissionTab',
  data() {
    return {
      activeMenuTab: [],
      allMenus: [
        {
          routeName: 'agent_commission',
          menuName: '分佣信息总览',
          iconType: 'fund',
          permission: 'agents/finance'
        },
        {
          routeName: 'agent_monthly_bills_commission',
          menuName: '月订单',
          iconType: 'account-book',
          permission: 'agent_monthly_bills'
        },
        {
          routeName: 'agent_withdrawals',
          menuName: '提现记录',
          iconType: 'transaction',
          permission: 'agents/withdrawals'
        },
        {
          routeName: 'agent_commission_statistics',
          menuName: '佣金余额',
          iconType: 'wallet',
          permission: 'agents/commission_statistics'
        }
      ],
      showHasPermissionMenus: [],
      agentMonthlyBillChildrenRouteNames: [
        'agent_monthly_bill_info_commission',
        'agent_monthly_bill_card_use_info_commission',
        'agent_monthly_bill_renew_order_info_commission'
      ]
    }
  },
  computed: {
    agentId() {
      return parseInt(this.$route.params.id)
    }
  },
  created() {
    this.setActiveMenuTab()
    this.fetchShowHasPermissionMenus()
  },
  methods: {
    setActiveMenuTab() {
      const routeName = this.$route.name
      if (this.agentMonthlyBillChildrenRouteNames.includes(routeName)) {
        this.activeMenuTab = ['agent_monthly_bills_commission']
      } else {
        this.activeMenuTab = [routeName]
      }
    },

    fetchShowHasPermissionMenus() {
      let isIncludeActiveMenuTab = false

      this.allMenus.forEach((value, index) => {
        if (this.isShowMenu(value.permission)) {
          this.showHasPermissionMenus.push(value)
          if (this.activeMenuTab[0] === value.routeName) {
            isIncludeActiveMenuTab = true
          }
        }
      })

      // 如果有权限的目录中不包含activeKey，那么activeKey 等于目录中第一个路径名
      if (!isIncludeActiveMenuTab) {
        this.activeKey = this.showHasPermissionMenus[0].routeName
      }
    },

    isShowMenu(menu) {
      return hasPermission(menu)
    }
  }
}
</script>
